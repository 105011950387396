<nb-card style="height: 90vh; min-height: 90vh; min-width: 80vw; max-width: 80vw; margin-top: 6px;">
    <nb-card-header style="background: rgb(50, 70, 90);">
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <span>CLERKNET TERMS AND PRIVACY POLICY</span>
            <button nbButton size="tiny" (click)="close()">
                <nb-icon icon="close-outline"></nb-icon>CLOSE
            </button>
        </div>
    </nb-card-header>
    <nb-card-body>
            <app-terms-clerknet></app-terms-clerknet>
    </nb-card-body>
</nb-card>