import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import {
  NbTooltipModule, NbIconModule, NbInputModule, NbButtonModule,
  NbTabsetModule, NbCardModule, NbListModule, NbCheckboxModule, NbSelectModule,
  NbDatepickerModule, NbProgressBarModule, NbRadioModule, NbCalendarModule, NbActionsModule, NbLayoutModule,
  NbContextMenuModule, NbRouteTabsetModule, NbAutocompleteModule, NbBadgeModule, NbToggleModule,
  NbAccordionModule, NbAlertModule, NbSpinnerModule, NbStepperModule, NbPopoverModule
} from '@nebular/theme';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import {TableModule} from 'primeng/table';
import { TermsComponent } from './terms/terms.component';
import {GalleriaModule} from 'primeng/galleria';
import { SupportComponent } from './support/support.component';
import { HttpClientModule } from '@angular/common/http';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgSelectModule } from '@ng-select/ng-select';
import {OrderListModule} from 'primeng/orderlist';

import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';
import { NgxMaskModule } from 'ngx-mask';
import { TermsErecordingComponent } from './terms-erecording/terms-erecording.component';
import { TermsClerknetComponent } from './terms-clerknet/terms-clerknet.component';
import { TermsEfilingComponent } from './terms-efiling/terms-efiling.component';

const nebularModules = [
  HttpClientModule,
  NbTooltipModule,
  NbIconModule,
  NbInputModule,
  NbButtonModule,
  NbTabsetModule,
  NbCardModule,
  NbListModule,
  NbCheckboxModule,
  NbSelectModule,
  NbDatepickerModule,
  NbProgressBarModule,
  NbRadioModule,
  NbCalendarModule,
  NbActionsModule,
  NbLayoutModule,
  NbContextMenuModule,
  NbAutocompleteModule,
  NbRouteTabsetModule,
  NbBadgeModule,
  NbStepperModule,
  NbDateFnsDateModule,
  NbToggleModule,
  NbAccordionModule,
  NbAlertModule,
  NbSpinnerModule,
  FlexLayoutModule,
  TableModule,
  GalleriaModule,
  NgxCurrencyModule,
  NgSelectModule,
  OrderListModule,
  NbPopoverModule,
  VgCoreModule,
  VgControlsModule,
  VgOverlayPlayModule,
  VgBufferingModule
];


@NgModule({
  declarations: [TermsComponent, SupportComponent, TermsErecordingComponent, TermsClerknetComponent, TermsEfilingComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    ...nebularModules,
    NgxStripeModule.forRoot(environment.stripe.recording),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [CurrencyPipe],
  exports: [
    ...nebularModules,
    ReactiveFormsModule,
    FormsModule,
    TermsErecordingComponent,
    TermsClerknetComponent,
    TermsEfilingComponent,
  ]
})
export class SharedModule { }
