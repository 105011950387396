import { Injectable } from '@angular/core';
import { NbToastrService, NbComponentStatus, NbToastrConfig, NbIconConfig } from '@nebular/theme';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private index = 0;

  constructor(
    public toastrService: NbToastrService
  ) { }

  showToast(title, details?, color?, position?) {
    let c = 'info';
    if (color) {
      c = color;
    }

    let m = '';
    if (details) {
      m = details;
    }
    let p = 'top-end';
    if (details) {
      m = details;
    }


    const iconConfig: NbIconConfig = { icon: 'info', pack: 'eva', status: 'info' };
    const status: NbComponentStatus = 'info';
    this.toastrService.show(
      m,
      title,
      iconConfig
      );
  }

  closeToast(): void {
  }

  showAlert(message): void {
    Swal.fire({
      html: `<span>${message}</span>`,
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
    }
    });
  }

  showAlertLoading(message): void {
    Swal.fire({
      html: `<span>${message}</span>`,
      showCloseButton: false,
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
    }
    });
  }

  updateAlert(message): void {
    Swal.update({
      html: `<span>${message}</span>`,
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
    });
  }

  closeAlert(): void {
    Swal.close();
  }

  async showConfirmDelete(alertTitle, message): Promise<any> {
    return Swal.fire({
       title: alertTitle,
       text: message,
       showCancelButton: true,
       confirmButtonColor: '#d91e18',
       cancelButtonColor: '#e4e9ed',
       confirmButtonText: 'YES - DELETE'
     }).then(async (result) => {
       return result;
     }).catch(err => console.log(err));
   }

   async showConfirmDialog(alertTitle, message): Promise<any> {
    return Swal.fire({
       title: alertTitle,
       text: message,
       showCancelButton: true,
       confirmButtonColor: '#00d78f',
       cancelButtonColor: '#d64541',
       confirmButtonText: 'YES'
     }).then(async (result) => {
       return result;
     }).catch(err => console.log(err));
   }

   async showConfirmOkButton(alertTitle, message): Promise<any> {
    return Swal.fire({
       title: alertTitle,
       text: message,
       showCancelButton: false,
       confirmButtonColor: '#00d78f',
       cancelButtonColor: '#d64541',
       confirmButtonText: 'OK'
     }).then(async (result) => {
       return result;
     }).catch(err => console.log(err));
   }

   async showConfirmOkButtonHtml(alertTitle, message): Promise<any> {
    return Swal.fire({
       title: alertTitle,
       html: message,
       showCancelButton: false,
       confirmButtonColor: '#00d78f',
       cancelButtonColor: '#d64541',
       confirmButtonText: 'OK'
     }).then(async (result) => {
       return result;
     }).catch(err => console.log(err));
   }

   
}
