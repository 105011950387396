import { Component, OnInit } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'app-terms-clerknet',
  templateUrl: './terms-clerknet.component.html',
  styleUrls: ['./terms-clerknet.component.scss']
})
export class TermsClerknetComponent implements OnInit {
  public clerk = environment.info;
  
  constructor() { }

  ngOnInit(): void {
  }

}
