import { Component, OnInit } from '@angular/core';
import { environment } from '@env';

@Component({
  selector: 'app-terms-efiling',
  templateUrl: './terms-efiling.component.html',
  styleUrls: ['./terms-efiling.component.scss']
})
export class TermsEfilingComponent implements OnInit {
  public clerk = environment.info;
  constructor() { }

  ngOnInit(): void {
  }

}
