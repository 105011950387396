export const environment = {
  production: true,
  info: {
    publicUrl: 'https://search.redriverclerk.com/login',
    district: '39TH',
    city: 'COUSHATTA',
    parish: 'RED RIVER',
    logo: 'seal.png',
    url: 'search.redriverclerk.com',
    clerkName: 'STUART R. SHAW',
    clerkAddress: 'PO BOX 485',
    clerkCityStateZip: 'COUSHATTA, LA  71019',
    clerkPhone: '(318) 932-6741',
    clerkWeb: 'www.redriverclerk.com',
    email: 'info@redriverclerk.com',
    civilFees: 'https://redriverclerk.com/civil/',
    videoUrl: 'https://firebasestorage.googleapis.com/v0/b/cn39rec_pub/o/videos%2FClerkNet_Search_Options.mp4?alt=media&token=2432370a-9557-4609-bff4-a62fb69cd596'
  },
  dates: {
    civil: 1420096285000,
    mortgages: 504947485000,
    conveyances: 504947485000,
    criminal: 1420096285000,
    recording: 504947485000,
    marriages: 504947485000,
  },
  recaptcha: {
    siteKey: '6LdLiAkfAAAAAFPOiMQDoGm8peuoy8vPVuoIZttK'
  },
  sb: {
    databaseurl: 'https://bblcpzcrhtuonfibrbbz.supabase.co',
      databasekey: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJibGNwemNyaHR1b25maWJyYmJ6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2Nzg2NTAyNjksImV4cCI6MTk5NDIyNjI2OX0.e_YFHMyPIdLwsldGE5JsucbmPaEZPH5hZ5Y9a-VJrf4', 
    i: 'cn39instruments',
    n: 'cn39names',
    url: 'https://qlcztzqvgzggxviiltlf.supabase.co',
    key: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTY0MDY0NTkyNywiZXhwIjoxOTU2MjIxOTI3fQ.dlPcTNw2bYgfj-i6994Z7UMuLk7pg5lq_KaVbjZR4Sk'
  },
  mconfig: {
    url: 'https://meili.clerknet.app',
    apiKey: '0ee5d62592b003e15706a25744b34f72bc52d03de8a5fa523f0fded6d480c6ca',
    civil: ''
  },
  aconfig: {
    appId: 'RZYQ3P4JBS',
    apiKey: '18f2496e8324ee32d8167b7e51f57e6d',
    CIVIL: 'rrp_civ',
    RECORDING: 'indexing',
    CRIMINAL: 'rrp_crim',
    AUDIT: 'publicAudit',
  },
  afs: {
    civ: 'clerknetapp.appspot.com',
    civpub: 'cn39civil_pub',
    civfolder: 'RRP_CIVIL_CLERKNET',
    crimpub: 'cn39crim_pub',
    crimfolder: 'cnCriminal',
    crim: 'cncrimerrp',
    recpub: 'cn39rec_pub',
    recfolder: '',
    rec: 'cnindexrrp',
    eRecording: 'cn39erecording',
    eFiling: 'cn39efiling',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyAVn5Odpqgr0iXlA2anVu4xpVPQNspPPxE',
    authDomain: 'clerknetapp.firebaseapp.com',
    databaseURL: 'https://clerknetapp.firebaseio.com',
    projectId: 'clerknetapp',
    storageBucket: 'clerknetapp.appspot.com',
    messagingSenderId: '866228253120',
    appId: '1:866228253120:web:7b4a927023e16bd03ad825',
  },
  stripe: {
    plans: [
      {
        name: '24 HOUR',
        rate: 20,
        fee: .91,
        total: 20.91,
        discount: '',
        planIdRenewal: 'price_1Jd1T0C9GoBG7VyMf7DUf3Pv',
        planId: 'price_1Jd1T0C9GoBG7VyMf7DUf3Pv'
      },
      {
        name: '1 MONTH',
        rate: 50,
        fee: 1.8,
        total: 51.80,
        discount: '',
        planIdRenewal: 'price_1Jd1T0C9GoBG7VyM0S5AzIwP',
        planId: 'price_1Jd1T0C9GoBG7VyMwQr2qvq9'
      },
      {
        name: '3 MONTH',
        rate: 150,
        fee: 4.79,
        total: 154.79,
        discount: '',
        planIdRenewal: 'price_1Jd1T0C9GoBG7VyML8L5PjKR',
        planId: 'price_1Jd1T0C9GoBG7VyM8Q4CZUMQ'
      },
      {
        name: 'YEARLY',
        rate: 570,
        fee: 17.33,
        total: 587.33,
        discount: '5% DISCOUNT',
        planIdRenewal: 'price_1Jd1T0C9GoBG7VyMhQQbKEx7',
        planId: 'price_1Jd1T0C9GoBG7VyMvgUqbMcg'
      }
    ],
    clerknet: 'pk_live_514ffBuFDbduv4AUe0WNSqOQXa9Ucn7XyYE4gGbHSPTQP9laJYhc0WLAC9pn8psLh7mV6Qq8NS7ta1PzSKUygzKvA00tZ0iBHHF',
    clerknetTest: 'pk_test_514ffBuFDbduv4AUey38zdX8utYa1IYYvQ4YMNpaWcTs7yQStA7RBIRAxH4h87pPb9W79GhSa1rUQj097h1CIiGhH00GRo130Q0',
    civilAcct: 'acct_1IWQ62Hm4994zlQ0',
    recAcct: 'acct_1CaAgmC9GoBG7VyM',
    recordingTest: 'pk_test_AAuVoZCSjq2Rh80vg46RBJRJ',
    recording: 'pk_live_fcFKh6PSHvhlz8GsjSnPXlJk',
    civil: 'pk_live_51IWQ62Hm4994zlQ0Stq15lWHXtNbwGlHEVHG7iBf8BdEwXKganI01y1ut96wwglc8zfnzkeu4v2bgQ77ZdDPgVpY00JDFNLGlj',
    civilTest: 'pk_test_51IWQ62Hm4994zlQ0f5NIBlCCDKp1pKm4r5AXf8aVSZ8PUhILKT2D523nyUNX7SSk3932k8Ix7xCL9KDCwygvZmFd00X8nsIC7G',
  },
};
