import { NgModule } from '@angular/core';
import {
  AngularFireAuthGuard,
  customClaims,
  emailVerified,
  loggedIn
} from '@angular/fire/auth-guard';

import { RouterModule, Routes } from '@angular/router';
import { TermsGuard } from '@core/guards/terms.guard';
import firebase from 'firebase/app'
import { forkJoin, of, pipe } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

const redirectIfNotVerifiedEmail = (redirect: any[]) =>
  pipe(
    switchMap((user: firebase.User) => {
      const isLoggedIn = loggedIn(of(user));
      const hasEmailedVerified = emailVerified(of(user));
      return forkJoin({ isLoggedIn, hasEmailedVerified });
    }),
    map(({ isLoggedIn, hasEmailedVerified }) => {
      if (!isLoggedIn) {
        return redirect[0];
      } else {
        if (!hasEmailedVerified) {
          return redirect[1];
        } else {
          return true;
        }
      }
    })
  );

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'marriage',
    loadChildren: () => import('./marriage/marriage.module').then((m) => m.MarriageModule),
  },
  {
    path: 'app',
    loadChildren: () => import('./core/layout/layout.module').then((m) => m.LayoutModule),
    canActivate: [AngularFireAuthGuard, TermsGuard],
    data: { authGuardPipe: () => redirectIfNotVerifiedEmail([['login'], ['login', 'verifyEmail']]) },
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
