import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'clerkNetPublic';

  public browserName = '';

  ngOnInit(): void {

    // this.browserName = this.detectBrowserName();
  }

  // detectBrowserName() { 
  //   const agent = window.navigator.userAgent.toLowerCase()
  //   switch (true) {
  //     case agent.indexOf('edge') > -1:
  //       return 'edge';
  //     case agent.indexOf('opr') > -1 && !!(<any>window).opr:
  //       return 'opera';
  //     case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
  //       return 'chrome';
  //     case agent.indexOf('trident') > -1:
  //       return 'ie';
  //     case agent.indexOf('firefox') > -1:
  //       return 'firefox';
  //     case agent.indexOf('safari') > -1:
  //       return 'safari';
  //     default:
  //       return 'other';
  //   }
  // }
}
