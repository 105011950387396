import { Component, OnInit } from '@angular/core';
import { environment } from '@env';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  public clerk = environment.info;
  constructor(
    private termDialog: NbDialogRef<TermsComponent>
  ) { }

  ngOnInit(): void {
  }

  close(): void {
    this.termDialog.close();
  }

}
