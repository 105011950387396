import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';
import { AuditService } from './services/audit.service';
import { AuthService } from './services/auth.service';
import { NotificationService } from './services/notification.service';

const modules = [AngularFireAuthModule, AngularFirestoreModule, AngularFireFunctionsModule, AngularFireStorageModule, AngularFireAnalyticsModule ];

@NgModule({
  declarations: [],
  imports: [CommonModule, AngularFireModule.initializeApp(environment.firebaseConfig), ...modules],
  exports: [...modules],
  providers: [AuthService, NotificationService, AuditService, UserTrackingService],
})
export class CoreModule {}
