import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@env';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { TermsComponent } from '@shared/terms/terms.component';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  public videoUrl: any;


  constructor(
    private supportDialog: NbDialogRef<SupportComponent>,
    private createDialog: NbDialogService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {

    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.info.videoUrl);
  }

  openTerms(): void {
    this.createDialog.open(TermsComponent);
  }

  close(): void {
    this.supportDialog.close();
  }

}
