<div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px" style="width: 100%;">
<nb-accordion>
    <nb-accordion-item>
        <nb-accordion-item-header>E-RECORDING TUTORIAL VIDEO</nb-accordion-item-header>
        <nb-accordion-item-body>
            <div style="position: relative; overflow: hidden; padding-top: 56.25%;"><iframe src="https://share.synthesia.io/4033f186-9e80-47e4-888c-1b669d690307" loading="lazy" title="ClerkNet E-Recording" allow="encrypted-media; fullscreen;" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0; margin: 0; overflow:hidden;"></iframe></div>
        </nb-accordion-item-body>
    </nb-accordion-item>
    <nb-accordion-item>
     <nb-accordion-item-header>FILE/DOCUMENT FORMAT</nb-accordion-item-header>
     <nb-accordion-item-body>
        <ul>
            <li>
                FILE MUST BE UPLOADED AS AN ADOBE PDF (.PDF) FILE FORMAT - NO IMAGE FILES OR WORD DOCUMENT .DOC FILES WILL BE ACCEPTED!
            </li>
            <li>
                FILE UPLOADS SHOULD BE IN BLACK AND WHITE AND LEGAL PAGE SIZE WITH A 2" MARGIN ON ALL SIDES!
            </li>
            <li>
                FILE CONTENT MUST BE CLEARLY VISIBLE AND NOT BLURRED - CLERKS MAY REJECT E-RECORDINGS IF THE CONTENT IS NOT CLEARLY VISIBLE
            </li>
            <li>
                FILE MUST NOT BE PASSWORD PROTECTED - CLERKS WILL REJECT E-RECORDINGS IF THE FILE IS PASSWORD PROTECTED
            </li>
            <li>
                CLERKS MAY REJECT E-RECORDINGS IF ANY OF THE ABOVE REQUIREMENTS ARE NOT SATISFIED!
            </li>
        </ul>
     </nb-accordion-item-body>
    </nb-accordion-item>

    <nb-accordion-item>
        <nb-accordion-item-header>E-RECORDING PROCESSING HOURS</nb-accordion-item-header>
        <nb-accordion-item-body>
          <ul>
              <li>
                   E-RECORDINGS ARE ONLY PROCESSED BY THE CLERK'S OFFICE BETWEEN 8:30 AM and 4:30 PM ON NORMAL BUSINESS DAYS EXCLUDING HOLIDAYS
              </li>
              <li>
                    E-RECORDINGS ARE PROCESSED IN THE ORDER THEY ARE RECEIVED, SO PLEASE SUBMIT YOUR E-RECORDINGS IN THE ORDER YOU WISH FOR THESE TO BE PROCESSED
               </li>
               <li>
                SUBMITTING AN E-RECORDING DOES NOT GUARANTEE THE INSTRUMENT WILL BE RECORDED. EACH E-RECORDING INSTRUMENT IS REVIEWED BY A RECORDING CLERK AND EITHER APPROVED OR REJECTED. IF YOUR E-RECORDING IS REJECTED, YOU WILL RECEIVE A NOTIFICATION INCLUDING A REJECTION REASON.
           </li>
          </ul>
        </nb-accordion-item-body>
       </nb-accordion-item>

       <nb-accordion-item>
        <nb-accordion-item-header>E-RECORDING PAYMENT METHODS</nb-accordion-item-header>
        <nb-accordion-item-body>
          <ul>
              <li>
                   E-RECORDING ACCOUNTS MUST HAVE A VALID PAYMENT METHOD LINKED TO THEIR ACCOUNT PRIOR TO SUBMITTING AN E-RECORDING
              </li>
              <li>
                    E-RECORDING FINANCIAL ACCOUNTS WILL BE CHARGED DURING THE CLERK REVIEW PROCESS, BUT BEFORE THE INSTRUMENT IS RECORDED. THE CLERK WILL ATTEMPT TO CHARGE THE PRIMARY PAYMENT METHOD PRIOR TO THE INSTRUMENT BEING RECORDED
               </li>
               <li style="font-weight: bold; color: red;">
                *** IF YOUR PRIMARY PAYMENT METHOD FAILS TO PROCESS FOR ANY REASON, THE E-RECORDING WILL BE REJECTED DUE TO A PAYMENT FAILURE. IT IS THE E-RECORDER'S RESPONSIBILITY TO ENSURE YOUR PAYMENT METHOD IS VALID.
           </li>
           <li style="font-weight: bold; color: red;">
            *** NO REFUNDS WILL BE ISSUED FOR E-RECORDINGS AND/OR PROCESSING FEES ***
       </li>
          </ul>
        </nb-accordion-item-body>
       </nb-accordion-item>

       <nb-accordion-item>
        <nb-accordion-item-header>RECORDING FEES</nb-accordion-item-header>
        <nb-accordion-item-body>
            <span>STANDARDIZED RECORDING FEES PURSUANT TO R.S.13:844 ACT 173 OF THE 2017 LEGISLATIVE SESSION EFFECTIVE AUGUST 1, 2017</span>
          <ul>
              
              <li style="color: red;">
                   1 to 5 Pages => $100.00 + $5.00 LCRAA FEE => $105.00 
               </li>
               <li style="color: red;">
                6 to 25 Pages => $200.00 + $5.00 LCRAA FEE => $205.00 
            </li>
            <li style="color: red;">
                26 to 50 Pages => $200.00 + $5.00 LCRAA FEE => $305.00 
            </li>
            <li style="color: red;">
                51 OR MORE Pages => $300.00 + $5.00 LCRAA FEE => $305.00 + $5.00 per page 
            </li>
            <br><br>
            <li style="color: red;">
                CANCELLATION => $55.00
            </li>
            <li style="color: red;">
                NOTICE OF REPOSSESSION => $75.00
            </li>
            <li >
                Index Fee after the 10th name => $5.00 per name
            </li>
            <li>
                ONE (1) ELECTRONIC CERTIFIED COPY WILL BE INCLUDED WITH EACH E-RECORDING. IF YOU WOULD LIKE A MAILED CERTIFIED COPY, PLEASE NOTATE THIS IN THE INSTRUCTION SECTION. $10.00 CERTIFIED COPY FEE + $0.53 POSTAGE WILL BE ADDED PER INSTRUMENT. 
            </li>
          </ul>
        </nb-accordion-item-body>
       </nb-accordion-item>

       <nb-accordion-item>
        <nb-accordion-item-header>PROCESSING FEES</nb-accordion-item-header>
        <nb-accordion-item-body>
          <ul>
            <li>
                ALL E-RECORDINGS WILL BE ASSESSED AN ONLINE FEE OF <strong>$3.00 PER INSTRUMENT</strong> PLUS PAYMENT PROCESSING FEES DEPENDING ON YOUR PAYMENT METHOD SELECTED<br>
           </li>
              <li>
                   ACH E-CHECK PAYMENTS WILL BE ASSESSED A SERVICE FEE OF 0.8% CAPPED AT $5.00 <br>
                   EXAMPLE => $105 ESTIMATED E-RECORDING FEE WILL CHARGE YOUR CREDIT CARD $105.00 + $3.00 ONLINE FEE + $0.87 ACH PROCESSING FEE => $108.87 TOTAL
              </li>
              <li>
                CREDIT CARD PAYMENTS WILL BE ASSESSED A SERVICE FEE OF 2.9% + $0.30 PER TRANSACTION<br>
                EXAMPLE => $105 ESTIMATED E-RECORDING FEE WILL CHARGE YOUR CREDIT CARD $105.00 + $3.00 ONLINE FEE + $3.53 CREDIT CARD PROCESSING FEE => $111.53 TOTAL
               </li>
               <li>
                E-RECORDING USERS ARE RESPONSIBLE FOR ALL PAYMENT PROCESSING FEES IN ADDITION TO CLERK RECORDING FEES<br>
               </li>
          </ul>
        </nb-accordion-item-body>
       </nb-accordion-item>

       

       <nb-accordion-item>
        <nb-accordion-item-header>EMAIL NOTIFICATIONS</nb-accordion-item-header>
        <nb-accordion-item-body>
          <ul>
              <li>
                   Users will receive email notifictions related to the status of each E-RECORDING. Emails are sent to the email address of the user's account.
              </li>
              <li>
                   EMAILS WILL BE SENT FROM EMAIL ACCOUNT NOREPLY@CLERKNET.APP => PLEASE MAKE SURE YOUR EMAIL IS CONFIGURED TO ACCEPT EMAILS FROM THIS ADDRESS!
               </li>
          </ul>
        </nb-accordion-item-body>
       </nb-accordion-item>

   </nb-accordion>
   <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="gappx" style="width: 100%; margin-top: 25px;">
    <img fxFlex="30" class="logo" [src]="'assets/images/e-recording.jpg'" />
   </div>
   </div>