<nb-card style="height: 90vh; min-height: 90vh; min-width: 80vw; max-width: 80vw; margin-top: 6px;">
    <nb-card-header style="background: rgb(46, 133, 214);">
        <div fxLayout="row" fxLayoutAlign="space-between start">
            <span>CLERKNET SUPPORT AND FAQ</span>
            <div fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="10px">
                <span>support@clerknet.app</span>
                <button nbButton size="tiny" (click)="close()">
                    <nb-icon icon="close-outline"></nb-icon>CLOSE
                </button>
            </div>
            
        </div>
    </nb-card-header>
    <nb-card-body>
        <nb-accordion>

            <nb-accordion-item>
             <nb-accordion-item-header>APP STUCK/NOT WORKING</nb-accordion-item-header>
             <nb-accordion-item-body>
                <span>Please periodically REFRESH or RELOAD your browser window which will address many issues with the
                    app.</span>
             </nb-accordion-item-body>
            </nb-accordion-item>

            <nb-accordion-item>
                <nb-accordion-item-header>SEARCH</nb-accordion-item-header>
                <nb-accordion-item-body>
                    <vg-player>
                        <vg-overlay-play></vg-overlay-play>
                        <vg-buffering></vg-buffering>
                        <vg-scrub-bar>
                          <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
                          <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
                        </vg-scrub-bar>
                        <vg-controls>
                          <vg-play-pause></vg-play-pause>
                          <vg-playback-button></vg-playback-button>
                          <vg-mute></vg-mute>
                          <vg-volume></vg-volume>
                          <vg-fullscreen></vg-fullscreen>
                        </vg-controls>
                        <video [vgMedia]="$any(media)" #media id="singleVideo" preload="auto" crossorigin>
                          <source [src]="this.videoUrl" type="video/mp4">
                        </video>
                      </vg-player>

 
                   <span>ClerkNet utilizes a "real-time" search platform for querying data. In order to search, simply type any Case #, instrument #, or name into the large search box. Results will appear and filter in real-time as you type. Adjust the parameters accordingly to further filter your needed results.</span>
                </nb-accordion-item-body>
               </nb-accordion-item>

            <nb-accordion-item>
                <nb-accordion-item-header>Printing/Downloads/PDF Files</nb-accordion-item-header>
                <nb-accordion-item-body>
                   <span>Please make sure to DISABLE any POP-UP Blockers and/or Virus Protection/Firewall Settings which may be blocking the file downloads. When you purchase prints, the PDF file is downloaded to your computer and should appear in your computer's Downloads Folder. If you notice the file is damaged, this is likely due to a Firewall setting blocking the files. Please update your settings and try the download again. Download Links are now available in your Account History Page. You will only be charged for the single Instrument print and accounts will be adjusted accordingly.</span>
                </nb-accordion-item-body>
               </nb-accordion-item>

               <nb-accordion-item>
                <nb-accordion-item-header>Browser Compatibility</nb-accordion-item-header>
                <nb-accordion-item-body>
                   <span>Please utilize Google Chrome's Browser for the best viewing experience. While the app does work on other browsers, we are currently only supporting Chrome.</span>
                </nb-accordion-item-body>
               </nb-accordion-item>

               <nb-accordion-item>
                <nb-accordion-item-header>Subscription/Fund Adjustments</nb-accordion-item-header>
                <nb-accordion-item-body>
                   <span>We will honor all previous subscriptions and Pre-Paid Fund accounts. We appreciate your patience as we work through this data migration. Please email us your old username and new account email address with any questions regarding this. support@clerknet.app</span>
                </nb-accordion-item-body>
               </nb-accordion-item>

               <nb-accordion-item>
                <nb-accordion-item-header>Credit Cards</nb-accordion-item-header>
                <nb-accordion-item-body>
                   <span>All Credit Card Processing is handled by our vendor Stripe. </span>
                </nb-accordion-item-body>
               </nb-accordion-item>

               <nb-accordion-item>
                <nb-accordion-item-header>E-FILING TUTORIAL VIDEO</nb-accordion-item-header>
                <nb-accordion-item-body>
                    <div style="position: relative; overflow: hidden; padding-top: 56.25%;"><iframe src="https://share.synthesia.io/embeds/videos/20742267-9458-4a9f-b0bf-dd060762f439" loading="lazy" title="Synthesia video player - ClerkNet Civil E-Filing" allow="encrypted-media; fullscreen;" style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0; margin: 0; overflow:hidden;"></iframe></div>
                </nb-accordion-item-body>
            </nb-accordion-item>
               
            </nb-accordion>

            <div fxLayout="row" fxLayoutAlign="center start" style="width: 100%;">
                <br>
                <span style="text-align: center; font-size: 1.1rem; color: rgb(247, 156, 38); align-self: center; font-weight: bold;">
                    <br>
                <span>PLEASE USE GOOGLE'S CHROME BROWSER FOR THE BEST EXPERIENCE!</span><br><br>

                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                    <img src="assets/images/chrome.png">
                    <button nbButton status="primary" size="small">
                        <a style="color: white" href="https://www.google.com/chrome">DOWNLOAD CHROME</a>
                    </button>
                </div>
                
                </span>
            </div>

            <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="gappx">
                <a (click)="openTerms()"><span style="font-weight: bold">ClerkNet Terms and Privacy Policy</span></a>
            </div>
            <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="gappx">
                <a href="https://clerknet.app" target="_blank">
                  <img style="width: 250px;" class="logo" src="../../assets/images/logo_full_blue.png" />
                </a>
                
              </div>

              <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="gappx">
                  <span style="text-align: center;">Copyright © 2024 ClerkNet Solutions, LLC. ALL RIGHTS RESERVED.</span>
              </div>

            

            
            
    </nb-card-body>
</nb-card>
